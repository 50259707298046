import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Welcome from "../modules/welcome/Welcome"

const IndexPage = () => (
  <GlobalCollection>
    <Welcome />
  </GlobalCollection>
)

export default IndexPage
