import React from "react"
import { Flex, Box } from "reflexbox"
import styled from "styled-components"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"
import Hilde from "../../images/profiel_foto.jpg"
import { Link } from "gatsby"

const Welcome = () => (
  <>
    <Blob cheight="430px" />
    <WelcomeWrapper>
      <Flex flexWrap="wrap-reverse">
        <Box width={[1, 1 / 2]} p={3} alignSelf="center">
          <TextBlock className="centered white large">
            Welkom bij Psychotherapie Hilde Van Heuven!{" "}
            <span role="img" aria-label="wave">
              👋
            </span>
          </TextBlock>
        </Box>
        <Flex
          width={[1, 1 / 2]}
          p={3}
          justifyContent="center"
          alignItems="center"
        >
          <StyledHilde src={Hilde} alt="Hilde Van Heuven" />
        </Flex>
      </Flex>
      <Box p={3}>
        <Styledh1>Welkom</Styledh1>
        <TextBlock>
          Mijn naam is <b>Hilde Van Heuven</b> en ik werk als oplossingsgerichte
          psychotherapeut.
        </TextBlock>
        <TextBlock>
          De rode draad in mijn jarenlange werkervaring is het begeleiden van
          (jong)volwassenen. Ik hou ervan om met en voor mensen te werken. Het
          welzijn van de cliënt is de doelstelling.{" "}
          <b>
            Samen zoeken naar oplossingen en dit op een zorgzame en
            wetenschappelijk onderbouwde manier
          </b>
          .
        </TextBlock>
        <TextBlock>
          De strategie en de waarden die ik hanteer zijn volgens het{" "}
          <b>oplossingsgerichte gedachtegoed</b>. Psycho-educatie en oefeningen
          komen in de eerste plaats uit de <b>Cognitieve Gedragstherapie</b>.{" "}
          Als gevorderd <b>Brainspottherapeut</b> heb ik de beste resultaten met{" "}
          <StyledLink to="/brainspotting">Brainspotting</StyledLink>.
          <br />
          Hersengebaseerde therapieën nemen een grote vlucht vanwege hun
          effectieve werking. Dit geldt ook voor <b>Brainspotting</b>. Het is
          niet alleen een snelle en effectieve methode voor behandeling van een
          trauma, maar ook bij emotionele blokkades, persoonlijke ontwikkeling
          en het verbeteren van prestaties.
        </TextBlock>
        <TextBlock>
          Verder ben ik als ervaringsdeskundige gespecialiseerd in{" "}
          <b>burn-out</b>. Daarnaast ben ik me gaan verdiepen in de onderwerpen{" "}
          <b>angst en zelfvertrouwen</b>. Ik heb mijzelf bijgeschoold tot{" "}
          <b>hartcoherentie-coach</b> (ademtechniek) omdat deze techniek een
          solider emotioneel platform biedt voor bovenstaande onderwerpen, een
          extra hulpmiddel bij herstel.
        </TextBlock>
        <TextBlock>
          Natuurlijk ben je ook voor <b>andere ongemakken</b> welkom. Ik kan je
          o.a. ondersteunen bij agressie, verdriet, relatieproblemen,
          echtscheiding, zelfontplooiing, assertiviteit, levensvragen en ouder
          worden. Vind je niet wat je zoekt? Bel of mail me gerust voor verdere
          informatie.
        </TextBlock>
        <TextBlock>
          We komen allemaal ooit in moeilijke situaties terecht die de balans in
          ons leven verstoren. Meestal verwerk je dit op je eigen manier en kan
          je weer voort. Soms mis je de kracht of inzicht om een nieuw evenwicht
          te vinden en dan kan professionele ondersteuning welkom zijn.
          Therapeutische gesprekken geven je de mogelijkheid om op adem te
          komen, stil te staan bij wat moeilijk loopt, en te zoeken naar hoe je
          verder kan gaan op een manier die beter aanvoelt.
        </TextBlock>
        <TextBlock>
          Je problemen of zorgen delen, is niet gemakkelijk. Nochtans, praten
          helpt! Het aanvaarden van ondersteuning is alvast een eerste stap in
          de goede richting.
        </TextBlock>
        <TextBlock>
          Bedankt om een kijkje te nemen op deze website. Hier krijg je
          informatie over de specialisaties, de redenen waarvoor je bij mij
          terecht kan, mijn opleidingen, de tarieven en de wegbeschrijving.{" "}
          <b>Aarzel niet om me te contacteren</b> als je nog bijkomende vragen
          hebt. Ik sta je graag te woord.
        </TextBlock>
      </Box>
    </WelcomeWrapper>
  </>
)

export default Welcome

const WelcomeWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const Styledh1 = styled.h1`
  border-bottom: none;
  margin-bottom: 25px;
  color: black;
`

const StyledHilde = styled.img`
  max-width: 90%;
  border-top-left-radius: 60%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  box-shadow: 0px 11px 52px 20px rgba(0, 0, 0, 0.3);
`

const StyledLink = styled(Link)`
  text-decoration: #00628f;
  color: black;
`
